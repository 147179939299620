<template>
  <div class="g-section order-container">
    <div class="g-wrap order-top">
      <div class="flex">
        <!-- <span>筛选</span> -->
        <el-input placeholder="请输入关键字查询" suffix-icon="el-icon-search" v-model="keyword" size="small">
        </el-input>
      </div>
      <el-button type="primary" @click="$router.push('/enterprise/order/create')" size="small" icon="el-icon-circle-plus-outline">发布人才订单</el-button>
    </div>
    <div class="g-wrap order-bottom">
      <div v-for="i in 6" class="order-item" :key="i">
        <div class="item-top">
           <div class="item-row-title">
            <div class="item-left">
              <span class="order-item-title">人才需求定单{{i}}200（人）</span>
            </div>
            <div class="item-right hand-pointer" @click="hadleShowSchool">
              意向院校（2）所
            </div>
          </div>
          <div class="item-job">
            <div class="item-left">
              <span class="mb-10">岗位1|5k-6k|专科及以上|深圳|计算机相关专业|100人</span>
            </div>
            <div class="item-right">
              <div class="right-btn"><span>详情</span></div>
            </div>
          </div>
          <div class="item-job">
            <div class="item-left">
              <span class="mb-10">岗位2|3-5k|专科及以上|深圳|100人</span>
            </div>
            <div class="item-right">
              <div class="right-btn"><span>详情</span></div>
            </div>
          </div>
        </div>
        <div class="item-bottom">
          <div>
            <span class="mr-20">当前状态：已发布</span>
            <span>发布时间：2020-12-31</span>
          </div>
          <div>
            <el-button type="text" icon="el-icon-share" size="small">分享</el-button>
            <el-button type="text" icon="el-icon-edit" size="small">编辑</el-button>
            <el-button type="text" icon="el-icon-delete" size="small">删除</el-button>
          </div>
        </div>
      </div>
    </div> 
  </div>
</template>

<script>
export default {
  data() {
    return {
      keyword: ''
    }
  },
  methods:{
    hadleShowSchool(){
      this.$router.push('/enterprise/order/seeker')
    }
  }
}
</script>
<style lang="scss" scoped>
.order-container {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.order-top {
  justify-content: space-between;
  margin-top: 20px;
}
.order-bottom {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.order-item {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
  border: 1px solid #e7e8ea;
  border-radius: 3px;
  .item-top {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .item-row-title{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 20px;

    }
    .order-item-title {
      display: flex;
      text-align: left;
      font-weight: 600;
    }
    .item-job {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      border-bottom: 1px solid #e7e8ea;
    }
    .item-left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
    }
    .item-right {
      display: flex;
      align-items: center;
      .right-btn {
        cursor: pointer;
        margin-left: 20px;
        display: flex;
        flex-direction: column;
      }
    }
  }
  .item-bottom {
    display: flex;
    border-top: 1px solid #e7e8ea;
    background: #fbfbfb;
    height: 32px;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
  }
  .el-button {
    padding: 0px;
  }
}
</style>